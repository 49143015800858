import { AsyncAction, Action } from 'overmind'
import { CreatePlayerDto, LineSendDto, PlayerRemovedDto } from '../models'
import { GameExistsDto } from '../models'
import { PlayerJoinedDto } from '../models'
import { PlayerUpdatedDto } from '../models'
import { CountdownStartedDto } from '../models'
import { GameStartedDto } from '../models'
import { NewPlayerLineDto } from '../models'
import { ResetGameDto } from '../models'
import { CalculationCompletedDto } from '../models'
import { PlayerState } from '../models'
import { GameState } from './state'
import { CachedPlayerInfo } from './effects/storage'
import effects from './effects'
import LogRocket from 'logrocket'

export const setShowQR: Action<boolean> = ({ state }, show) => {
  state.showQR = show
}

export const createGame: AsyncAction = async ({ state, effects }) => {
  const result = await effects.api.createGame()
  state.gameId = result.id
  effects.router.navigate(`g/${result.id}`)

  LogRocket.identify({
    gameId: state.gameId,
  })
}

export const checkIfGameExists: AsyncAction<string, GameExistsDto> = async ({ effects }, gameId) => {
  return effects.api.gameExists(gameId)
}

export const joinGame: AsyncAction<CreatePlayerDto> = async ({ state, effects }, input) => {
  const result = await effects.api.joinGame(input)

  state.player = {
    id: result.id,
    name: result.name,
    imageId: result.imageId,
    state: 'joined',
    lines: [],
    size: { width: 0, height: 0 },
    connected: false,
  }

  state.gameId = input.gameId

  effects.router.navigate(`/p`)

  LogRocket.identify({
    gameId: state.gameId,
  })
}

export const connectPlayer: AsyncAction<{ height: number; width: number }> = async (
  { state, effects, actions },
  size
) => {
  if (!state.gameId || !state.player) {
    await actions.reconnectPlayer()
    return
  }

  const result = await effects.socket.connectPlayer({
    gameId: state.gameId,
    playerId: state.player.id,
    width: size.width,
    height: size.height,
  })
  state.connected = result.success
  state.player.connected = result.success
  state.gameState = result.gameState as GameState
  state.player.state = result.playerState as PlayerState

  effects.storage.saveItem<CachedPlayerInfo>('player-info', {
    gameId: state.gameId,
    playerId: state.player.id,
    name: state.player.name,
    imageId: state.player.imageId,
    size: size,
  })
}

export const reconnectPlayer: AsyncAction = async ({ state, effects }) => {
  console.log('trying to reconnect')
  const playerInfo = effects.storage.getItem<CachedPlayerInfo>('player-info')
  if (!playerInfo) {
    console.log('couldnt reconnect, redirect?')
    effects.storage.removeItems('player-info')
    return
  }

  const result = await effects.socket.reconnectPlayer({
    gameId: playerInfo.gameId,
    playerId: playerInfo.playerId,
  })

  if (!result.success) {
    console.log('couldnt reconnect, redirect?')
    effects.storage.removeItems('player-info')
    return
  }

  state.connected = true
  state.gameId = playerInfo.gameId
  state.gameState = result.gameState as GameState
  state.player = {
    id: playerInfo.playerId,
    name: playerInfo.name,
    imageId: playerInfo.imageId,
    state: result.playerState as PlayerState,
    connected: true,
    lines: [], // TODO cache lines
    size: playerInfo.size,
  }
}

export const connectOverview: AsyncAction = async ({ state, effects }) => {
  if (!state.gameId) return //throw new Error(`Can't connect without game id`)

  const connected = await effects.socket.connectOverview(state.gameId)
  state.connected = connected
}

export const sendNewLine: Action<LineSendDto> = ({ state, effects }, line) => {
  if (!state.gameId || !state.player) return

  effects.socket.sendLine({
    gId: state.gameId,
    pId: state.player?.id,
    d: line,
  })
}

export const sendDebugMessage: Action = () => {
  effects.socket.sendDebug()
}

export const onPlayerJoined: Action<PlayerJoinedDto> = ({ state }, payload) => {
  state.players[payload.id] = payload
}

export const onPlayerUpdated: Action<PlayerUpdatedDto> = ({ state }, payload) => {
  state.players[payload.id] = payload
}

export const onPlayerRemoved: Action<PlayerRemovedDto> = ({ state }, payload) => {
  delete state.players[payload.id]
}

export const onCountdownStarted: Action<CountdownStartedDto> = ({ state }, payload) => {
  state.gameState = 'countdown'
  state.currentMotif = payload.motif

  // Horrible hack D:
  if (state.countdownTime === payload.ms) state.countdownTime = payload.ms + 1
  else state.countdownTime = payload.ms
}

export const onCountdownStopped: Action = ({ state }) => {
  state.gameState = 'waiting'
  state.currentMotif = null
}

export const onGameStarted: Action<GameStartedDto> = ({ state }, payload) => {
  state.gameState = 'playing'
  state.gameTime = payload.ms
}

export const onGameStopped: Action = ({ state }) => {
  state.gameState = 'inactive'
}

export const onCalculationStarted: Action = ({ state }) => {
  state.gameState = 'calculating'
}

export const onCalculationCompleted: Action<CalculationCompletedDto> = ({ state }, payload) => {
  state.gameState = 'resetting'
  state.scores = payload.scores
  state.resetTime = payload.resetTime
}

export const onGameReset: Action<ResetGameDto> = ({ state }, payload) => {
  state.gameState = 'waiting'
  state.currentMotif = null
  state.scores = {}

  Object.values(state.players).forEach((p) => {
    p.lines = []
    p.state = payload.playerStates.find((x) => x.id === p.id)?.state as PlayerState
  })

  if (state.player) {
    state.player.lines = []
    state.player.state = payload.playerStates.find((x) => x.id === state.player?.id)?.state ?? 'joined'
  }
}

export const onNewLine: Action<NewPlayerLineDto> = ({ state }, payload) => {
  if (!state.players[payload.pId]) return
  state.players[payload.pId].lines.push(payload.d)
}
