import React from 'react'
import { RouteComponentProps } from '@reach/router'
import styled from 'styled-components'
import Header from '../components/Header'

function NotFoundPage(props: RouteComponentProps) {
  return (
    <Wrapper>
      <Header></Header>
      <main>Not found :(</main>
    </Wrapper>
  )
}

export default NotFoundPage

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
