import { CreatedGameDto } from '../../models'
import { GameExistsDto } from '../../models'
import { CreatePlayerDto } from '../../models'
import { CreatePlayerResponseDto } from '../../models'
import http from './http'

const SERVER_URL = process.env.REACT_APP_SERVER_URL || 'http://localhost:3001'

const api = {
  createGame: async (): Promise<CreatedGameDto> => {
    return http.post<CreatedGameDto>(`${SERVER_URL}/game`)
  },
  gameExists: async (id: string): Promise<GameExistsDto> => {
    return http.get<GameExistsDto>(`${SERVER_URL}/game/${id}`)
  },
  joinGame: async (input: CreatePlayerDto): Promise<CreatePlayerResponseDto> => {
    return http.post(`${SERVER_URL}/game/join`, input)
  },
}

export default api
