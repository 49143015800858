import React, { useState } from 'react'
import styled from 'styled-components'
import { useOvermind } from '../overmind'

type InfoBoxProps = {
  label?: string
  copy?: string
  qr?: boolean
  className?: string
  children: React.ReactNode
  style?: React.CSSProperties
}

export default function InfoBox({ label, copy, children, style, className, qr }: InfoBoxProps) {
  const { actions } = useOvermind()
  const [copyText, setCopyText] = useState('Click to copy link!')
  const onCopy = async () => {
    if (!copy) return

    if (navigator.clipboard) await navigator.clipboard.writeText(copy)
    else copyToClipboard(copy)

    setCopyText('Copied!')
    setTimeout(() => {
      setCopyText('Click to copy link!')
    }, 2000)
  }

  return (
    <Wrapper style={style} className={className}>
      {label && <span>{label}</span>}
      <Content qr={qr}>
        {copy && (
          <ButtonsWrapper>
            <CopyButton onClick={onCopy}>{copyText}</CopyButton>
            <QRButton onClick={() => actions.setShowQR(true)}>
              <svg
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path d="M12 4v1m6 11h2m-6 0h-2v4m0-11v3m0 0h.01M12 12h4.01M16 20h4M4 12h4m12 0h.01M5 8h2a1 1 0 001-1V5a1 1 0 00-1-1H5a1 1 0 00-1 1v2a1 1 0 001 1zm12 0h2a1 1 0 001-1V5a1 1 0 00-1-1h-2a1 1 0 00-1 1v2a1 1 0 001 1zM5 20h2a1 1 0 001-1v-2a1 1 0 00-1-1H5a1 1 0 00-1 1v2a1 1 0 001 1z"></path>
              </svg>
            </QRButton>
            <QRText>Show QR code</QRText>
          </ButtonsWrapper>
        )}
        {children}
      </Content>
    </Wrapper>
  )
}

const Content = styled.div<{ qr?: boolean }>`
  font-family: 'Fredoka One', cursive;
  font-size: 32px;
  text-align: center;
  border: 3px dashed #9692af;
  border-radius: 8px;
  padding: 4px 12px;
  ${(p) => p.qr && 'padding-right: 42px;'}
  margin-top: 4px;
  overflow: hidden;
  display: flex;
  align-items: center;

  &::selection {
    background-color: #69779b;
  }

  > span {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;

    > svg {
      height: 100%;
    }
  }
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 200px;
`

const CopyButton = styled.button`
  flex-grow: 1;
  cursor: pointer;
  background: none;
  border: none;
  color: white;
  opacity: 0;
  font-size: 24px;
  text-shadow: 1px 1px black;
  font-weight: bold;
  transition: opacity 0.3s ease;
  user-select: none;
  z-index: 10;

  &:hover {
    opacity: 1;
  }
`

const QRText = styled.div`
  position: absolute;
  top: 0;
  right: 40px;
  left: 0;
  bottom: 0;
  opacity: 0;
  transition: opacity 0.3s ease;
  text-shadow: 1px 1px black;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Inter, sans-serif;
  font-size: 24px;
`

const QRButton = styled.button`
  display: flex;
  height: 100%;
  width: 40px;
  cursor: pointer;
  background: none;
  border: none;
  color: white;
  font-size: 24px;
  text-shadow: 1px 1px black;
  font-weight: bold;
  transition: opacity 0.3s ease;
  user-select: none;

  > svg {
    height: 100%;
  }

  &:hover + ${QRText} {
    opacity: 1;
  }
`

const ButtonsWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  display: flex;
  z-index: 10;
  background-color: rgba(38, 78, 112, 0);
  transition: background-color 0.3s ease;

  &:hover {
    background-color: rgba(38, 78, 112, 0.8);
  }
`

function copyToClipboard(val: string) {
  const dummy = document.createElement('input')
  dummy.setAttribute('hidden', 'true')
  document.body.appendChild(dummy)

  dummy.setAttribute('id', 'dummy_id')
  const el = document.getElementById('dummy_id') as HTMLInputElement
  el!.value = val
  dummy.select()
  document.execCommand('copy')
  document.body.removeChild(dummy)
}
