import { derived } from 'overmind'
import { Line } from '../components/Canvas/CanvasTypes'
import { mockScores, mockPlayers } from './_stateMocks'

const debug = false

export type Player = {
  id: string
  name: string
  imageId: number
  size: {
    width: number
    height: number
  }
  state: 'created' | 'joined' | 'queued'
  lines: Line[] // TODO these models are a bit mixed up, refactor
  connected: boolean
}

export type GameState = 'waiting' | 'countdown' | 'playing' | 'calculating' | 'resetting' | 'inactive'

export type State = {
  title: string | null
  connected: boolean
  player: Player | null
  gameId: string | null
  gameState: GameState
  countdownTime: number
  gameTime: number
  resetTime: number
  currentMotif: string | null
  players: Record<string, Player>
  playersList: Player[]
  playerCount: number
  scores: Record<string, number>
  winnerIds: string[]
  showQR: boolean
}

export const state: State = {
  title: 'draw.wtf',
  connected: false,
  player: null,
  gameId: null,
  gameState: 'waiting',
  countdownTime: 0,
  gameTime: 0,
  resetTime: 0,
  currentMotif: null,
  scores: debug ? mockScores : {},
  players: debug ? mockPlayers : {},
  showQR: false,

  playersList: derived<State, Player[]>((state) =>
    Object.values(state.players).filter((x) => x.state === 'joined' || x.state === 'queued')
  ),
  playerCount: derived<State, number>((state) => Object.keys(state.players).length),
  winnerIds: derived<State, string[]>((state) =>
    Object.keys(state.scores).filter((x) => state.scores[x] === Math.max(...Object.values<number>(state.scores)))
  ),
}
