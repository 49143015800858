import React from 'react'
import styled from 'styled-components'
import { useOvermind } from '../overmind'
import { QRCode } from 'react-qr-svg'

export default function QRModal() {
  const { state, actions } = useOvermind()

  if (!state.showQR) return null

  const onClose = () => {
    actions.setShowQR(false)
  }

  return (
    <Wrapper onClick={onClose}>
      <Inner>
        <QRCode bgColor="#DDDDDD" fgColor="#111111" level="Q" value={`https://draw.wtf/${state.gameId}`} />
      </Inner>
      <span>Click anywhere to close</span>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: hsla(208, 49%, 29%, 0.9);
  z-index: 99999;
`

const Inner = styled.div`
  width: 100%;
  height: auto;
  max-width: 500px;
  max-height: 500px;
`
