import React from 'react'
import ReactDOM from 'react-dom'
import * as Sentry from '@sentry/browser'
import { Router } from '@reach/router'
import LogRocket from 'logrocket'
import setupLogRocketReact from 'logrocket-react'
import { createOvermind } from 'overmind'
import { Provider } from 'overmind-react'
import { config } from './overmind'
import * as serviceWorker from './serviceWorker'
import CreatePage from './pages/CreatePage'
import JoinPage from './pages/JoinPage'
import GamePage from './pages/GamePage'
import NotFoundPage from './pages/NotFoundPage'
import './index.css'
import PlayPage from './pages/PlayPage'
import QRModal from './components/QRModal'
// import Debugger from './components/Debugger'

if (process.env.NODE_ENV === 'production') {
  Sentry.init({ dsn: 'https://f3ebb1db81be4f29b06e7cc6bb366b0f@o120760.ingest.sentry.io/5215559' })
  LogRocket.init('0q16x9/drawwtf')
  setupLogRocketReact(LogRocket)
  LogRocket.getSessionURL((sessionURL) => {
    Sentry.configureScope((scope) => {
      scope.setExtra('sessionURL', sessionURL)
    })
  })
}

const overmind = createOvermind(config)

ReactDOM.render(
  <React.StrictMode>
    <Provider value={overmind}>
      {/* <Debugger></Debugger> */}
      <Router>
        <CreatePage path="/" />
        <JoinPage path="/:gameId" gameId="" />
        <GamePage path="/g/:gameId" gameId="" />
        <PlayPage path="/p" />
        <NotFoundPage default={true} />
      </Router>
      <QRModal />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
