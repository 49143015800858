import React from 'react'
import styled from 'styled-components'

type HeaderProps = {
  tight?: boolean
}

const Header = ({ tight }: HeaderProps) => {
  return (
    <Wrapper>
      <h1 style={{ margin: tight ? '1rem' : undefined }}>
        draw.
        <Red>w</Red>
        <Green>t</Green>
        <Yellow>f</Yellow>
      </h1>
    </Wrapper>
  )
}

export default Header

const Wrapper = styled.header`
  font-family: 'Fredoka One', cursive;
  font-size: 32px;
  font-size: min(max(16px, 8vw), 48px);
`

const Red = styled.span`
  color: #ffb4ac;
`

const Green = styled.span`
  color: #a7e9af;
`

const Yellow = styled.span`
  color: #eef9bf;
`
