import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { RouteComponentProps } from '@reach/router'
import Header from '../components/Header'
import PeepSelect from '../components/PeepSelect'
import { useOvermind } from '../overmind'

interface JoinPageProps extends RouteComponentProps {
  gameId: string
}

function JoinPage({ gameId }: JoinPageProps) {
  const [name, setName] = useState('')
  const [imageId, setImageId] = useState<number | null>(null)
  const [state, setState] = useState<'loading' | 'loaded' | 'error'>('loading')
  const { effects, actions } = useOvermind()

  useEffect(() => {
    actions.checkIfGameExists(gameId).then((result) => {
      if (result.exists) setState('loaded')
      else setState('error')
    })
  }, [gameId, effects, actions])

  const handleJoin = () => {
    if (!name || !imageId) return
    actions.joinGame({ name, imageId, gameId })
  }

  return (
    <Wrapper>
      <Header tight={true}></Header>
      {state === 'error' && <p>Game not found :( Make sure you have the right link!</p>}
      {state === 'loaded' && (
        <>
          <Label>
            <span>Name</span>
            <Input type="text" value={name} onChange={(e) => setName(e.target.value)}></Input>
          </Label>
          <Button onClick={handleJoin} disabled={name.length === 0}>
            Join room
          </Button>
          <StyledPeepSelect onChange={setImageId}></StyledPeepSelect>
        </>
      )}
    </Wrapper>
  )
}

export default JoinPage

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Label = styled.label`
  display: flex;
  flex-direction: column;
  font-size: 20px;
  font-weight: 800;
  letter-spacing: 1.4px;

  span {
    padding-left: 14px;
  }
`

const Input = styled.input`
  box-sizing: border-box;
  height: 45px;
  width: 285px;
  font-size: 24px;
  padding: 8px 16px;
  border: none;
  border-radius: 20px;

  &:focus {
    outline: none;
    border: 2px solid #f76a8c;
  }
`

const Button = styled.button`
  padding: 12px 36px;
  background-color: #f76a8c;
  color: #fffdf6;
  border: none;
  border-radius: 24px;
  font-size: 24px;
  width: 285px;
  margin-top: 20px;

  &:disabled {
    background-color: #aaa;
    color: #ddd;
    cursor: not-allowed;
  }
`

const StyledPeepSelect = styled(PeepSelect)`
  width: 285px;
  margin-top: 20px;
`
