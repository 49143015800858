import React from 'react'
import styled from 'styled-components'
import screenfull from 'screenfull'

const toggleFullScreen = () => {
  if (screenfull.isEnabled) {
    screenfull.toggle()
  }
}

export default function FullscreenButton() {
  return (
    <Wrapper onClick={toggleFullScreen}>
      <svg
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path d="M4 8V4m0 0h4M4 4l5 5m11-1V4m0 0h-4m4 0l-5 5M4 16v4m0 0h4m-4 0l5-5m11 5l-5-5m5 5v-4m0 4h-4"></path>
      </svg>
    </Wrapper>
  )
}

const Wrapper = styled.button`
  width: 24px;
  height: 24px;
  border: 0;
  background: none;
  color: white;
  position: absolute;
  top: 8px;
  right: 8px;
  padding: 0;
  cursor: pointer;
  opacity: 0.5;

  &:hover {
    opacity: 1;
  }
`
