import React, { useEffect, useCallback, useState } from 'react'
import { RouteComponentProps } from '@reach/router'
import styled from 'styled-components'
import { nanoid } from 'nanoid'
import Confetti from 'react-dom-confetti'
import DrawingArea from '../components/DrawingArea'
import InfoBox from '../components/InfoBox'
import { useOvermind } from '../overmind'
import Countdown from '../components/Countdown'

const confettiConfig = {
  angle: 90,
  spread: 40,
  startVelocity: 50,
  elementCount: 150,
  dragFriction: 0.1,
  duration: 6000,
  stagger: 15,
  colors: ['#a864fd', '#29cdff', '#78ff44', '#ff718d', '#fdff6a'],
}

function PlayPage(props: RouteComponentProps) {
  const [resetCanvasKey, setResetCanvasKey] = useState(nanoid(4))
  const { state, actions, reaction } = useOvermind()

  const handleDrawingAreaSize = useCallback(
    (bounds) => {
      state.connected
        ? console.log('update size')
        : actions.connectPlayer({ width: bounds.width, height: bounds.height })
    },
    [actions, state.connected]
  )

  useEffect(() =>
    reaction(
      ({ scores }) => scores,
      (scores) => {
        if (Object.keys(scores).length === 0) setResetCanvasKey(nanoid(4))
      }
    )
  )

  return (
    <Wrapper>
      <Motif label="Motif:">{state.currentMotif || '...'}</Motif>
      <main>
        {state.gameState === 'countdown' && (
          <Overlay>
            <Countdown time={state.countdownTime}></Countdown>
          </Overlay>
        )}
        <DrawingArea
          onSizeSet={handleDrawingAreaSize}
          disabled={state.gameState !== 'playing' || state.player?.state === 'queued'}
          resetCanvasKey={resetCanvasKey}
        >
          {state.gameState === 'waiting' && <span>Waiting for game to start...</span>}
          {state.player?.state === 'queued' && <span>Waiting for next round</span>}
        </DrawingArea>
      </main>
      <Instructions>
        <StyledConfetti active={!!state.player && state.winnerIds.includes(state.player.id)} config={confettiConfig} />
        <h2>How to play</h2>
        <p>
          When the game starts, you can start drawing in the canvas. You can also see what the rounds motif is above the
          canvas.
        </p>
        <h2>Something is wrong!</h2>
        <p>If you get disconnected or something else stops working, try refreshing this page.</p>
      </Instructions>
    </Wrapper>
  )
}

export default PlayPage

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
`

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 25vw;
`

const Instructions = styled.div`
  width: 300px;
  width: min(max(200px, 95vw), 400px);
  margin-bottom: 24px;

  > h2 {
    font-family: 'Fredoka One', cursive;
    margin-bottom: 0px;
  }

  > p {
    margin: 0;
  }
`

const Motif = styled(InfoBox)`
  margin-top: 48px;
  margin-top: min(max(8px, 6vw), 48px);
  margin-bottom: 16px;
  text-transform: capitalize;
  display: block;
`

const StyledConfetti = styled(Confetti)`
  position: absolute !important;
  z-index: 999999;
  top: 50%;
  left: 50%;
`
