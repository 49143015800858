import React, { useEffect, useCallback } from 'react'
import Canvas from './Canvas/Canvas'
import styled from 'styled-components'
import { useOvermind } from '../overmind'
import { Line } from './Canvas/CanvasTypes'
import useMeasure, { RectReadOnly } from 'react-use-measure'
import { ResizeObserver } from '@juggle/resize-observer'

type DrawingAreaProps = {
  disabled?: boolean
  children?: React.ReactNode | React.ReactNode[]
  resetCanvasKey: string
  onSizeSet?: (bounds: RectReadOnly) => void
}

function DrawingArea({ disabled, children, onSizeSet, resetCanvasKey }: DrawingAreaProps) {
  const { actions } = useOvermind()
  const [ref, bounds] = useMeasure({ debounce: 200, polyfill: ResizeObserver })

  const handleNewLine = useCallback(
    (line: Line) => {
      actions.sendNewLine({
        points: line.points,
      })
    },
    [actions]
  )

  useEffect(() => {
    if (bounds.height && bounds.width) {
      onSizeSet && onSizeSet(bounds)
    }
  }, [bounds, onSizeSet])

  return (
    <Wrapper ref={ref}>
      {disabled && <Overlay>{children}</Overlay>}
      <Canvas
        key={resetCanvasKey}
        onChange={handleNewLine}
        canvasWidth="100%"
        canvasHeight="100%"
        brushRadius={2}
        lazyRadius={2}
        hideInterface={true}
        disabled={disabled}
      ></Canvas>
    </Wrapper>
  )
}

export default DrawingArea

// TODO test mobile responsive
const Wrapper = styled.div`
  width: 400px;
  height: 400px;
  width: min(max(200px, 95vw), 450px);
  height: min(max(200px, 95vw), 450px);

  border-radius: 16px;
  overflow: hidden;
  user-select: none;
`

const Overlay = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: hsla(208, 49%, 29%, 0.5);
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;

  > span {
    font-family: 'Fredoka One', cursive;
    font-size: 36px;
    font-size: min(max(14px, 8vw), 36px);
    color: hsl(208, 49%, 29%);
    width: 300px;
    width: min(max(100px, 90%), 300px);
  }
`
