// rewritten from https://github.com/embiem/react-canvas-draw

import React, { useRef, useState, useEffect, useCallback } from 'react'
import { LazyBrush } from 'lazy-brush'
import { Catenary } from 'catenary-curve'
import ResizeObserver from 'resize-observer-polyfill'
import { CanvasType, Point, Line, CanvasData } from './CanvasTypes'
const catenary = new Catenary()

// import drawImage from "./drawImage";
type CanvasProps = {
  onChange?: (line: Line) => void
  loadTimeOffset: number
  lazyRadius: number
  brushRadius: number
  brushColor: string
  catenaryColor: string
  gridColor: string
  backgroundColor: string
  hideGrid: boolean
  canvasWidth: string
  canvasHeight: string
  disabled: boolean
  imgSrc: string
  saveData: CanvasData | null
  immediateLoading: boolean
  iterativeLoading: boolean
  hideInterface: boolean
  className?: string
  style?: React.CSSProperties
}

function Canvas({ onChange, ...props }: CanvasProps) {
  const canvases = useRef<Record<string, HTMLCanvasElement>>({})
  const ctxs = useRef<Record<string, CanvasRenderingContext2D>>({})
  const canvasContainer = useRef<HTMLDivElement>(null!)
  const lazy = useRef<any>(null)
  const canvasObserver = useRef<ResizeObserver>()
  const mouseHasMoved = useRef(true)
  const valuesChanged = useRef(true)

  const ps = useRef<Point[]>([])
  const lines = useRef<Line[]>([])
  const [chainLength, setChainLength] = useState<number>(0)

  const isDrawing = useRef(false)
  const isPressing = useRef(false)

  const triggerOnChange = useCallback(
    (line) => {
      onChange && onChange(line)
    },
    [onChange]
  )

  const drawInterface = useCallback(
    (ctx: CanvasRenderingContext2D, pointer: Point, brush: Point) => {
      if (props.hideInterface) return

      ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height)

      // Draw brush preview
      ctx.beginPath()
      ctx.fillStyle = props.brushColor
      ctx.arc(brush.x, brush.y, props.brushRadius, 0, Math.PI * 2, true)
      ctx.fill()

      // Draw mouse point (the one directly at the cursor)
      ctx.beginPath()
      ctx.fillStyle = props.catenaryColor
      ctx.arc(pointer.x, pointer.y, 4, 0, Math.PI * 2, true)
      ctx.fill()

      // Draw catenary
      if (lazy.current.isEnabled()) {
        ctx.beginPath()
        ctx.lineWidth = 2
        ctx.lineCap = 'round'
        ctx.setLineDash([2, 4])
        ctx.strokeStyle = props.catenaryColor
        catenary.drawToCanvas(ctxs.current.interface, brush, pointer, chainLength)
        ctx.stroke()
      }

      // Draw brush point (the one in the middle of the brush preview)
      ctx.beginPath()
      ctx.fillStyle = props.catenaryColor
      ctx.arc(brush.x, brush.y, 2, 0, Math.PI * 2, true)
      ctx.fill()
    },
    [chainLength, props.brushColor, props.brushRadius, props.catenaryColor, props.hideInterface]
  )

  const loop = useCallback(
    ({ once = false } = {}) => {
      if (mouseHasMoved || valuesChanged) {
        const pointer = lazy.current.getPointerCoordinates()
        const brush = lazy.current.getBrushCoordinates()

        drawInterface(ctxs.current.interface, pointer, brush)
        mouseHasMoved.current = false
        valuesChanged.current = false
      }

      if (!once) {
        window.requestAnimationFrame(() => {
          loop()
        })
      }
    },
    [drawInterface]
  )

  const clear = () => {
    lines.current = []
    valuesChanged.current = true
    ctxs.current.drawing.clearRect(0, 0, canvases.current.drawing.width, canvases.current.drawing.height)
    ctxs.current.temp.clearRect(0, 0, canvases.current.temp.width, canvases.current.temp.height)
  }

  const drawPoints = ({
    points,
    brushColor,
    brushRadius,
  }: {
    points: Point[]
    brushColor: string
    brushRadius: number
  }) => {
    ctxs.current.temp.lineJoin = 'round'
    ctxs.current.temp.lineCap = 'round'
    ctxs.current.temp.strokeStyle = brushColor

    ctxs.current.temp.clearRect(0, 0, ctxs.current.temp.canvas.width, ctxs.current.temp.canvas.height)
    ctxs.current.temp.lineWidth = brushRadius * 2

    let p1 = points[0]
    let p2 = points[1]

    ctxs.current.temp.moveTo(p2.x, p2.y)
    ctxs.current.temp.beginPath()

    for (var i = 1, len = points.length; i < len; i++) {
      // we pick the point between pi+1 & pi+2 as the
      // end point and p1 as our control point
      var midPoint = midPointBtw(p1, p2)
      ctxs.current.temp.quadraticCurveTo(p1.x, p1.y, midPoint.x, midPoint.y)
      p1 = points[i]
      p2 = points[i + 1]
    }
    // Draw last line as a straight line while
    // we wait for the next point to be able to calculate
    // the bezier control point
    ctxs.current.temp.lineTo(p1.x, p1.y)
    ctxs.current.temp.stroke()
  }

  const handlePointerMove = (x: number, y: number) => {
    if (props.disabled) return

    lazy.current?.update({ x, y })
    const isDisabled = !lazy.current?.isEnabled()

    if ((isPressing.current && !isDrawing.current) || (isDisabled && isPressing.current)) {
      // Start drawing and add point
      isDrawing.current = true
      ps.current.push(lazy.current.brush.toObject())
    }

    if (isDrawing.current) {
      // Add new point
      ps.current.push(lazy.current.brush.toObject())

      // Draw current points
      drawPoints({
        points: ps.current,
        brushColor: props.brushColor,
        brushRadius: props.brushRadius,
      })
    }

    mouseHasMoved.current = true
  }

  const handleDrawStart = (e: any) => {
    e.preventDefault()

    // Start drawing
    isPressing.current = true

    const { x, y } = getPointerPos(e)

    if (e.touches && e.touches.length > 0) {
      // on touch, set catenary position to touch pos
      lazy.current.update({ x, y }, { both: true })
    }

    // Ensure the initial down position gets added to our line
    handlePointerMove(x, y)
  }

  const handleDrawMove = (e: any) => {
    e.preventDefault()

    const { x, y } = getPointerPos(e)
    handlePointerMove(x, y)
  }

  const handleDrawEnd = (e: any) => {
    e.preventDefault()

    // Draw to this end pos
    handleDrawMove(e)

    // Stop drawing & save the drawn line
    isDrawing.current = false
    isPressing.current = false
    saveLine()
  }

  const saveLine = useCallback(
    ({ brushColor, brushRadius }: { brushColor?: string; brushRadius?: number } = {}) => {
      if (ps.current.length < 2) return

      const newLine = {
        points: [...ps.current],
        brushColor: brushColor || props.brushColor,
        brushRadius: brushRadius || props.brushRadius,
      }
      // Save as new line
      lines.current.push(newLine)
      triggerOnChange(newLine)

      // Reset points array
      ps.current = []

      const width = canvases.current.temp.width
      const height = canvases.current.temp.height

      // Copy the line to the drawing canvas
      ctxs.current.drawing.drawImage(canvases.current.temp, 0, 0, width, height)

      // Clear the temporary line-drawing canvas
      ctxs.current.temp.clearRect(0, 0, width, height)
    },
    [props.brushColor, props.brushRadius, triggerOnChange]
  )

  const simulateNewLines = useCallback(
    ({ lines }: { lines: Line[] }) => {
      lines.forEach((line) => {
        const { points, brushColor, brushRadius } = line

        drawPoints({ points, brushColor, brushRadius })

        ps.current = points
        saveLine({ brushColor, brushRadius })
      })
    },
    [saveLine]
  )

  const simulateDrawingLines = useCallback(
    ({ lines, immediate }: { lines: Line[]; immediate: boolean }) => {
      // Simulate live-drawing of the loaded lines
      // TODO use a generator
      let curTime = 0
      let timeoutGap = immediate ? 0 : props.loadTimeOffset

      lines.forEach((line) => {
        const { points, brushColor, brushRadius } = line

        // Draw all at once if immediate flag is set, instead of using setTimeout
        if (immediate) {
          // Draw the points
          drawPoints({
            points,
            brushColor,
            brushRadius,
          })

          // Save line with the drawn points
          ps.current = points
          saveLine({ brushColor, brushRadius })
          return
        }

        // Use timeout to draw
        for (let i = 1; i < points.length; i++) {
          curTime += timeoutGap
          window.setTimeout(() => {
            drawPoints({
              points: points.slice(0, i + 1),
              brushColor,
              brushRadius,
            })
          }, curTime)
        }

        curTime += timeoutGap
        window.setTimeout(() => {
          // Save this line with its props instead of this.props
          ps.current = points
          saveLine({ brushColor, brushRadius })
        }, curTime)
      })
    },
    [props.loadTimeOffset, saveLine]
  )

  const loadSaveData = useCallback(
    ({ lines, width, height }: CanvasData, immediate = props.immediateLoading) => {
      if (!lines || typeof lines.push !== 'function') {
        throw new Error('saveData.lines needs to be an array!')
      }

      clear()

      if (width === props.canvasWidth && height === props.canvasHeight) {
        simulateDrawingLines({
          lines,
          immediate,
        })
      } else {
        // we need to rescale the lines based on saved & current dimensions
        const scaleX = parseInt(props.canvasWidth) / parseInt(width)
        const scaleY = parseInt(props.canvasHeight) / parseInt(height)
        const scaleAvg = (scaleX + scaleY) / 2

        simulateDrawingLines({
          lines: lines.map((line) => ({
            ...line,
            points: line.points.map((p: Point) => ({
              x: p.x * scaleX,
              y: p.y * scaleY,
            })),
            brushRadius: line.brushRadius * scaleAvg,
          })),
          immediate,
        })
      }
    },
    [props.canvasHeight, props.canvasWidth, props.immediateLoading, simulateDrawingLines]
  )

  const iterativeLoadSavedata = useCallback(
    ({ lines, width, height }: CanvasData) => {
      // TODO improve performance of this, actually redraws every line now because of rerendering
      if (width === props.canvasWidth && height === props.canvasHeight) {
        simulateNewLines({
          lines,
        })
      } else {
        const scaleX = parseInt(props.canvasWidth) / parseInt(width)
        const scaleY = parseInt(props.canvasHeight) / parseInt(height)
        const scaleAvg = (scaleX + scaleY) / 2

        simulateNewLines({
          lines: lines.map((line) => ({
            ...line,
            points: line.points.map((p: Point) => ({
              x: p.x * scaleX,
              y: p.y * scaleY,
            })),
            brushRadius: line.brushRadius * scaleAvg,
          })),
        })
      }
    },
    [props.canvasHeight, props.canvasWidth, simulateNewLines]
  )

  const getPointerPos = (e: any): Point => {
    const rect = canvases.current.interface.getBoundingClientRect()

    // use cursor pos as default
    let clientX = e.clientX
    let clientY = e.clientY

    // use first touch if available
    if (e.changedTouches && e.changedTouches.length > 0) {
      clientX = e.changedTouches[0].clientX
      clientY = e.changedTouches[0].clientY
    }

    // return mouse/touch position inside canvas
    return {
      x: clientX - rect.left,
      y: clientY - rect.top,
    }
  }

  const drawGrid = useCallback(
    (ctx: CanvasRenderingContext2D) => {
      if (props.hideGrid) return

      ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height)

      ctx.beginPath()
      ctx.setLineDash([5, 1])
      ctx.setLineDash([])
      ctx.strokeStyle = props.gridColor
      ctx.lineWidth = 0.5

      const gridSize = 25

      let countX = 0
      while (countX < ctx.canvas.width) {
        countX += gridSize
        ctx.moveTo(countX, 0)
        ctx.lineTo(countX, ctx.canvas.height)
      }
      ctx.stroke()

      let countY = 0
      while (countY < ctx.canvas.height) {
        countY += gridSize
        ctx.moveTo(0, countY)
        ctx.lineTo(ctx.canvas.width, countY)
      }
      ctx.stroke()
    },
    [props.gridColor, props.hideGrid]
  )

  const getSaveData = useCallback((): CanvasData => {
    // Construct and return the stringified saveData object
    return {
      lines: lines.current,
      width: props.canvasWidth,
      height: props.canvasHeight,
    }
  }, [props.canvasHeight, props.canvasWidth])

  const handleCanvasResize = useCallback(
    (entries: ResizeObserverEntry[], observer: ResizeObserver) => {
      const saveData = getSaveData()
      for (const entry of entries) {
        const { width, height } = entry.contentRect
        setCanvasSize(canvases.current.interface, width, height)
        setCanvasSize(canvases.current.drawing, width, height)
        setCanvasSize(canvases.current.temp, width, height)
        setCanvasSize(canvases.current.grid, width, height)

        drawGrid(ctxs.current.grid)
        loop({ once: true })
      }
      loadSaveData(saveData, true)
    },
    [drawGrid, getSaveData, loadSaveData, loop]
  )

  const setCanvasSize = (canvas: HTMLCanvasElement, width: number, height: number) => {
    canvas.width = width
    canvas.height = height
    canvas.style.width = `${width}px`
    canvas.style.height = `${height}px`
  }

  useEffect(() => {
    lazy.current = new LazyBrush({
      radius: props.lazyRadius * window.devicePixelRatio,
      enabled: true,
      initialPoint: {
        x: window.innerWidth / 2,
        y: window.innerHeight / 2,
      },
    })

    setChainLength(props.lazyRadius * window.devicePixelRatio)

    canvasObserver.current = new ResizeObserver((entries, observer) => handleCanvasResize(entries, observer))
    const currentContainer = canvasContainer.current
    canvasObserver.current.observe(currentContainer)

    loop()

    const initX = window.innerWidth / 2
    const initY = window.innerHeight / 2
    lazy.current.update({ x: initX - chainLength / 4, y: initY }, { both: true })
    lazy.current.update({ x: initX + chainLength / 4, y: initY }, { both: false })
    mouseHasMoved.current = true
    valuesChanged.current = true
    clear()

    if (props.saveData) {
      if (props.iterativeLoading) {
        iterativeLoadSavedata(props.saveData)
      } else {
        loadSaveData(props.saveData)
      }
    }

    return () => canvasObserver.current?.unobserve(currentContainer)
  }, [
    props.lazyRadius,
    props.saveData,
    props.iterativeLoading,
    chainLength,
    loadSaveData,
    iterativeLoadSavedata,
    loop,
    handleCanvasResize,
  ])

  return (
    <div
      className={props.className}
      style={{
        display: 'block',
        background: props.backgroundColor,
        touchAction: 'none',
        width: props.canvasWidth,
        height: props.canvasHeight,
        ...props.style,
      }}
      ref={canvasContainer}
    >
      {canvasTypes.map(({ name, zIndex }) => {
        const isInterface = name === 'interface'
        return (
          <canvas
            key={name}
            ref={(canvas) => {
              if (canvas) {
                canvases.current[name] = canvas
                const canvasCtx = canvas.getContext('2d')
                if (canvasCtx) ctxs.current[name] = canvasCtx
              }
            }}
            style={{
              display: 'block',
              position: 'absolute',
              zIndex,
            }}
            onMouseDown={isInterface ? handleDrawStart : undefined}
            onMouseMove={isInterface ? handleDrawMove : undefined}
            onMouseUp={isInterface ? handleDrawEnd : undefined}
            onMouseOut={isInterface ? handleDrawEnd : undefined}
            onTouchStart={isInterface ? handleDrawStart : undefined}
            onTouchMove={isInterface ? handleDrawMove : undefined}
            onTouchEnd={isInterface ? handleDrawEnd : undefined}
            onTouchCancel={isInterface ? handleDrawEnd : undefined}
          />
        )
      })}
    </div>
  )
}

const defaultProps: CanvasProps = {
  onChange: () => {},
  loadTimeOffset: 5,
  lazyRadius: 12,
  brushRadius: 10,
  brushColor: '#444',
  catenaryColor: '#0a0302',
  gridColor: 'rgba(150,150,150,0.17)',
  backgroundColor: '#FFF',
  hideGrid: false,
  canvasWidth: '400px',
  canvasHeight: '400px',
  disabled: false,
  imgSrc: '',
  saveData: null,
  immediateLoading: false,
  iterativeLoading: true,
  hideInterface: false,
}
Canvas.defaultProps = defaultProps

export default Canvas

function midPointBtw(p1: Point, p2: Point): Point {
  return {
    x: p1.x + (p2.x - p1.x) / 2,
    y: p1.y + (p2.y - p1.y) / 2,
  }
}

const canvasTypes: CanvasType[] = [
  {
    name: 'interface',
    zIndex: 15,
  },
  {
    name: 'drawing',
    zIndex: 11,
  },
  {
    name: 'temp',
    zIndex: 12,
  },
  {
    name: 'grid',
    zIndex: 10,
  },
]
