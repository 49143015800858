import React, { useState, useEffect, useCallback } from 'react'
import styled from 'styled-components'

type PeepSelectProps = {
  className?: string
  onChange?: (id: number) => void
}

const maxImage = 105

const PeepSelect = ({ className, onChange }: PeepSelectProps) => {
  const [image, setImage] = useState<number | null>(null)

  const changeImage = useCallback(() => {
    const id = randomNumber(maxImage)
    setImage(id)
    onChange && onChange(id)
  }, [onChange])

  useEffect(() => changeImage(), [changeImage])

  return (
    <Wrapper className={className}>
      <ImageButton onClick={changeImage}>
        <img
          src={`${process.env.PUBLIC_URL}/images/peep-${image}.svg`}
          alt="profile"
        ></img>
      </ImageButton>
    </Wrapper>
  )
}

export default PeepSelect

const Wrapper = styled.div`
  background-color: #d7eaea;
  border-radius: 16px;
  width: 100%;
  display: flex;
  margin-bottom: 36px;
`

const ImageButton = styled.button`
  border: none;
  background: none;
  outline: none;
  cursor: pointer;
  width: 100%;
`

const randomNumber = (max: number): number =>
  Math.floor(Math.random() * max) + 1
