import React, { useEffect, useState, useRef } from 'react'
import parseMs from 'parse-ms'

type Props = {
  time: number
}

export default function Countdown({ time }: Props) {
  const [timeLeft, setTimeLeft] = useState(time)

  useEffect(() => {
    setTimeLeft(time)
  }, [time])

  useInterval(
    () => {
      if (timeLeft > 0) setTimeLeft((t) => t - 1000)
    },
    time ? 1000 : null
  )

  const { seconds } = parseMs(timeLeft)

  return <span>{seconds}</span>
}

function useInterval(callback: Function, delay: number | null) {
  const savedCallback = useRef<Function>()

  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  useEffect(() => {
    function tick() {
      if (savedCallback.current) savedCallback.current()
    }
    if (delay !== null) {
      let id = setInterval(tick, delay)
      return () => clearInterval(id)
    }
  }, [delay])
}
