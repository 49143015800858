import React from 'react'
import { RouteComponentProps } from '@reach/router'
import styled from 'styled-components'
import { useOvermind } from '../overmind'
import Header from '../components/Header'
import { FeedbackFish } from '@feedback-fish/react'

function CreatePage(props: RouteComponentProps) {
  const { actions } = useOvermind()

  const handleCreate = () => {
    actions.createGame()
  }

  return (
    <Wrapper>
      <Header></Header>
      <Main>
        <Button onClick={handleCreate}>Create room</Button>
        <Description>
          <h2>What is this?</h2>
          <p>
            <b>draw.wtf</b> is a silly drawing game that you play together with your friends. Each round everyone draws
            a random motif with the goal of receiving the highest score from a (somewhat shitty) AI.
          </p>
          <p>
            Everyone needs to be able to see <i>this</i> screen when you play, so if you aren't in the same room I
            recommend screen sharing :)
          </p>
        </Description>
      </Main>
      <Footer>
        <FeedbackFish projectId="e62966f40a4e00">
          <button>Feedback or bug reports?</button>
        </FeedbackFish>
      </Footer>
    </Wrapper>
  )
}

export default CreatePage

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  min-height: 100vh;
`

const Main = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Button = styled.button`
  padding: 12px 36px;
  background-color: #f76a8c;
  color: #fffdf6;
  border: none;
  border-radius: 24px;
  font-size: 28px;
`

const Description = styled.div`
  width: 350px;
  width: min(max(200px, 95vw), 450px);
  margin-top: 2rem;
  margin-bottom: 16px;

  > h2 {
    font-family: 'Fredoka One', cursive;
    margin-bottom: 0px;
  }

  > p {
    margin: 0;
  }

  > p + p {
    margin-top: 1rem;
  }
`

const Footer = styled.div`
  margin-top: auto;
  margin-bottom: 16px;
  color: rgba(255, 255, 255, 0.6);

  button {
    background: none;
    border: none;
    cursor: pointer;
    color: rgba(255, 255, 255, 0.6);

    &:hover {
      color: white;
    }
  }
`
