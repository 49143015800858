import { OnInitialize } from 'overmind'
import { MessageType } from '../models'

export const onInitialize: OnInitialize = async ({ effects, actions }) => {
  effects.socket.initialize({
    messageHandlers: [
      {
        type: MessageType.playerJoined,
        handler: actions.onPlayerJoined,
      },
      {
        type: MessageType.playerUpdated,
        handler: actions.onPlayerUpdated,
      },
      {
        type: MessageType.playerRemoved,
        handler: actions.onPlayerRemoved,
      },
      {
        type: MessageType.countdownStarted,
        handler: actions.onCountdownStarted,
      },
      {
        type: MessageType.countdownStopped,
        handler: actions.onCountdownStopped,
      },
      {
        type: MessageType.gameStarted,
        handler: actions.onGameStarted,
      },
      {
        type: MessageType.gameStopped,
        handler: actions.onGameStopped,
      },
      {
        type: MessageType.calculationStarted,
        handler: actions.onCalculationStarted,
      },
      {
        type: MessageType.calculationCompleted,
        handler: actions.onCalculationCompleted,
      },
      {
        type: MessageType.resetGame,
        handler: actions.onGameReset,
      },
      {
        type: MessageType.newLine,
        handler: actions.onNewLine,
      },
    ],
  })
}
