import React, { useEffect, useState } from 'react'
import { RouteComponentProps } from '@reach/router'
import styled, { css, keyframes } from 'styled-components'
import InfoBox from '../components/InfoBox'
import { useOvermind } from '../overmind'
import PlayerCard from '../components/PlayerCard'
import ResponsiveGrid from '../components/ResponsiveGrid'
import Countdown from '../components/Countdown'
import { State } from '../overmind/state'
import FullscreenButton from '../components/FullscreenButton'
import { GameStatsDto } from '../models'
import * as Dialog from '@radix-ui/react-dialog'

interface JoinPageProps extends RouteComponentProps {
  gameId: string
}

function GamePage({ gameId }: JoinPageProps) {
  const { actions, state } = useOvermind()

  useEffect(() => {
    actions.connectOverview()
  }, [actions])

  return (
    <Wrapper>
      <FullscreenButton></FullscreenButton>
      <HeaderText>{stateToDescription(state)}</HeaderText>
      <Header>
        <InfoBox label="Keep this page open and join at:" copy={`https://draw.wtf/${gameId}`} qr>
          draw.wtf/{gameId}
        </InfoBox>
        <MotifBox label="Motif:">{state.currentMotif || '...'}</MotifBox>
      </Header>
      <GameArea>
        <Overlay visible={state.gameState === 'countdown'}>
          <Countdown time={state.countdownTime}></Countdown>
        </Overlay>
        {state.playerCount > 0 ? (
          <ResponsiveGrid>
            {state.playersList.map((p) => (
              <PlayerCard
                key={p.id}
                player={p}
                score={state.scores[p.id]}
                winner={state.winnerIds.includes(p.id)}
              ></PlayerCard>
            ))}
          </ResponsiveGrid>
        ) : (
          state.gameState !== 'inactive' && <Placeholder>WAITING FOR PLAYERS</Placeholder> //TODO replace with arrow pointing to join instruction?
        )}
      </GameArea>
      <Stats gameId={gameId}></Stats>
    </Wrapper>
  )
}

export default GamePage

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  padding: 32px;
  box-sizing: border-box;
  overflow: hidden;
`

const Header = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
  align-items: flex-end;
  margin-bottom: 16px;

  > *:not(:last-child) {
    margin-right: 16px;
  }
`

const HeaderText = styled.div`
  font-size: 2rem;
  font-family: 'Fredoka One', cursive;
  min-width: 250px;
  text-align: center;
  margin-bottom: 1rem;
`

const GameArea = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  margin-top: 1rem;
  margin-bottom: 1rem;
`

const Overlay = styled.div<{ visible: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  background-color: rgba(38, 78, 112, ${(p) => (p.visible ? 0.7 : 0)});
  z-index: 10;
  transition: background-color 0.5s;
  font-size: 25vw;

  > * {
    display: ${(p) => (p.visible ? 'visible' : 'none')};
  }
`

const Placeholder = styled.span`
  font-size: 52px;
  text-align: center;
  font-family: 'Fredoka One', cursive;
  opacity: 0.7;
`

const MotifBox = styled(InfoBox)`
  text-transform: capitalize;
`

const SERVER_URL = process.env.REACT_APP_SERVER_URL || 'http://localhost:3001'
const Stats = ({ gameId }: { gameId: string }) => {
  const [open, setOpen] = useState(false)
  const [stats, setStats] = useState<GameStatsDto>()
  const { state } = useOvermind()

  useEffect(() => {
    if (!open) return

    fetch(`${SERVER_URL}/game/${gameId}/stats`)
      .then((r) => r.json())
      .then((r) => setStats(r))
  }, [open, gameId])

  const allScores = stats?.players.flatMap((x) => Object.values(x.scores).map((y) => y.score)) || [0]
  const allAvgs = stats?.players.map((x) => scoreAvg(x.scores)) || [0]

  const mostWins = stats ? Math.max(...stats?.players.map((x) => x.wins)) : 0
  const highestScore = Math.max(...allScores)
  const lowestScore = Math.min(...allScores)
  const bestAvg = Math.max(...allAvgs)

  return (
    <Dialog.Root open={open} onOpenChange={setOpen}>
      <StatsButton>Stats</StatsButton>
      <Dialog.Portal>
        <StatsOverlay />
        <StatsContent>
          <StatsTitle>{stats?.rounds} rounds played</StatsTitle>
          <StatsTable>
            <thead>
              <tr>
                <StatsTableHeading>Player</StatsTableHeading>
                <StatsTableHeading>Wins</StatsTableHeading>
                <StatsTableHeading>Best</StatsTableHeading>
                <StatsTableHeading>Worst</StatsTableHeading>
                <StatsTableHeading>Avg</StatsTableHeading>
              </tr>
            </thead>
            <tbody>
              {stats?.players.map((p) => (
                <StatsRow disconnected={!Boolean(state.players[p.id])}>
                  <StatsCell bold={false}>{state.players[p.id]?.name || 'N/A'}</StatsCell>
                  <StatsCell bold={p.wins === mostWins}>{p.wins}</StatsCell>
                  <StatsCell bold={p.bestMotif?.score === highestScore}>
                    {p.bestMotif?.score} ({p.bestMotif?.motif})
                  </StatsCell>
                  <StatsCell bold={p.worstMotif?.score === lowestScore}>
                    {p.worstMotif?.score} ({p.worstMotif?.motif})
                  </StatsCell>
                  <StatsCell bold={scoreAvg(p.scores) === bestAvg}>{scoreAvg(p.scores)}</StatsCell>
                </StatsRow>
              ))}
            </tbody>
          </StatsTable>
        </StatsContent>
      </Dialog.Portal>
    </Dialog.Root>
  )
}

const scoreAvg = (scores: GameStatsDto['players'][0]['scores']) => {
  return Math.floor(scores.reduce((acc, cur) => acc + cur.score, 0) / scores.length)
}

const StatsOverlay = styled(Dialog.Overlay)`
  background-color: hsl(0 0% 10% / 0.45);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  position: absolute;
`

const StatsButton = styled(Dialog.Trigger)`
  background-color: white;
  font-weight: bold;
  color: hsl(0 0% 20% / 1);
  border-radius: 6px 6px 0 0;
  padding: 4px 8px;
  font-size: 16px;
  line-height: 1;
  border: 0;
  position: absolute;
  bottom: 0;
  cursor: pointer;

  &:hover {
    color: hsl(0 0% 0% / 1);
  }
`

const StatsTitle = styled.h2`
  margin-top: 0;
`

const slideIn = keyframes`
  0% {
    opacity: 1;
    transform: translate(-50%, 50vh) scale(0.9);
  }

  100% {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
`

const StatsContent = styled(Dialog.Content)`
  z-index: 99;
  background-color: white;
  color: black;
  border-radius: 6px;
  box-shadow: rgb(14 18 22 / 35%) 0px 10px 38px -10px, rgb(14 18 22 / 20%) 0px 10px 20px -15px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90vw;
  max-width: 650px;
  max-height: 85vh;
  padding: 25px;
  animation: 750ms cubic-bezier(0.16, 1, 0.3, 1) 0s 1 normal none running ${slideIn};
`

const StatsTable = styled.table`
  width: 100%;
  border: 0;
`

const StatsRow = styled.tr<{ disconnected: boolean }>`
  ${({ disconnected }) =>
    disconnected
      ? css`
          color: hsl(0 0% 60% / 1);
        `
      : ''}

  > td {
    background-color: transparent;
  }

  &::nth-child(even) {
    background-color: gray;
  }
`

const StatsCell = styled.td<{ bold: boolean }>`
  font-weight: ${({ bold }) => (bold ? 'bold' : 'normal')};
`

const StatsTableHeading = styled.th`
  text-align: left;
`

function stateToDescription(state: State): React.ReactNode {
  switch (state.gameState) {
    case 'waiting':
      return 'Waiting for players..'
    case 'countdown':
      return 'Starting game!'
    case 'playing':
      return (
        <span>
          Do your best! <Countdown time={state.gameTime}></Countdown>s left!
        </span>
      )
    case 'calculating':
      return 'Calculating scores..'
    case 'resetting':
      return (
        <span>
          Round over! Resetting in <Countdown time={state.resetTime}></Countdown>s
        </span>
      )
    case 'inactive':
      return 'Game ended'
  }
}
