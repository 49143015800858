/* HERE BE DRAGONS :( */
import React, { useMemo } from 'react'
import styled from 'styled-components'
import useMeasure, { RectReadOnly } from 'react-use-measure'
import { ResizeObserver } from '@juggle/resize-observer'

type ResponsiveGridProps = {
  children: React.ReactNode[]
}

const maxColumns = 5
const minSize = 200
const maxSize = 500
const gap = 16

export const GridContext = React.createContext(0)

export default function ResponsiveGrid({ children }: ResponsiveGridProps) {
  const [ref, bounds] = useMeasure({ polyfill: ResizeObserver })

  const count = children.length
  const [max, colCount] = useMemo((): number[] => {
    let colCount = count > maxColumns ? maxColumns : count
    let size = calculateSize(colCount, count, gap, bounds)

    while (size < minSize) {
      colCount = colCount - 1
      size = calculateSize(colCount, count, gap, bounds)
    }

    return [size, colCount]
  }, [count, bounds])

  return (
    <Wrapper ref={ref}>
      <Grid maxWidth={max} columns={colCount} gap={gap}>
        <GridContext.Provider value={max}>{children}</GridContext.Provider>
      </Grid>
    </Wrapper>
  )
}

const calculateSize = (columns: number, count: number, gap: number, bounds: RectReadOnly): number => {
  const c = columns > maxColumns ? maxColumns : columns

  let max = (bounds.width - (c - 1) * gap) / c

  if (bounds.height) {
    const rows = Math.ceil(count / maxColumns)
    const maxFromHeight = (bounds.height - (rows - 1) * gap) / rows

    if (maxFromHeight < max) {
      max = maxFromHeight < minSize ? minSize : maxFromHeight
    }
  }

  return max > maxSize ? maxSize : max
}

const Wrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  max-width: 100%;
  height: 100%;
`

const Grid = styled.div<{ maxWidth: number; columns: number; gap: number }>`
  display: grid;
  justify-content: center;
  ${(p) => `grid-template-columns: repeat(${p.columns}, ${p.maxWidth}px);`}
  grid-auto-rows: 1fr;
  grid-gap: ${(p) => p.gap}px;
  width: 100%;

  &::before {
    content: '';
    width: 0;
    padding-bottom: 100%;
    grid-row: 1 / 1;
    grid-column: 1 / 1;
  }

  > *:first-child {
    grid-row: 1 / 1;
    grid-column: 1 / 1;
  }
`
