import { IConfig } from 'overmind'
import { createHook } from 'overmind-react'
import { state } from './state'
import { onInitialize } from './onInitialize'
import effects from './effects'
import * as actions from './actions'

export const config = {
  onInitialize,
  state,
  actions,
  effects
}

declare module 'overmind' {
  interface Config extends IConfig<typeof config> {}
}

export const useOvermind = createHook<typeof config>()
