// These should be imported from the server, but CRA doesn't support typescript references yet: https://github.com/facebook/create-react-app/issues/6799

enum GameState {
  waiting = 'waiting',
  countdown = 'countdown',
  playing = 'playing',
  calculating = 'calculating',
  resetting = 'resetting',
  inactive = 'inactive',
}

export enum PlayerState {
  created = 'created',
  joined = 'joined',
  queued = 'queued',
}

// Might need this in the future:
// https://github.com/facebook/create-react-app/issues/3547
export enum MessageType {
  connectPlayer = 'connect-player',
  reconnectPlayer = 'reconnect-player',
  connectOverview = 'connect-overview',
  playerJoined = 'player-joined',
  playerUpdated = 'player-updated',
  playerRemoved = 'player-removed',
  countdownStarted = 'countdown-started',
  countdownStopped = 'countdown-stopped',
  gameStarted = 'game-started',
  gameStopped = 'game-stopped',
  calculationStarted = 'calculation-started',
  calculationCompleted = 'calculation-completed',
  resetGame = 'reset-game',
  newLine = 'new-line',
  debug = 'debug',
}

export class ConnectPlayerDto {
  gameId!: string
  playerId!: string
  height!: number
  width!: number
}

export class ConnectPlayerResponseDto {
  success!: boolean
  gameState?: GameState
  playerState?: PlayerState
}

export class ReconnectPlayerDto {
  gameId!: string
  playerId!: string
}

export class ReconnectPlayerResponseDto {
  success!: boolean
  gameState?: GameState
  playerState?: PlayerState
}

export class NewPlayerLineDto {
  gId?: string
  pId!: string
  d!: LineDto
}

export class NewPlayerLineSendDto {
  gId?: string
  pId!: string
  d!: LineSendDto
}

export class LineSendDto {
  brushColor?: string
  brushRadius?: number
  points!: {
    x: number
    y: number
  }[]
}
export class LineDto {
  brushColor!: string
  brushRadius!: number
  points!: {
    x: number
    y: number
  }[]
}

export class CreatedGameDto {
  id!: string
}

export class GameExistsDto {
  exists!: boolean
}

export class CreatePlayerDto {
  name!: string
  imageId!: number
  gameId!: string
}

export class CreatePlayerResponseDto {
  id!: string
  name!: string
  imageId!: number
}

export class PlayerJoinedDto {
  id!: string
  name!: string
  imageId!: number
  size!: { width: number; height: number }
  state!: PlayerState
  lines!: LineDto[]
  connected!: boolean
}

export class PlayerUpdatedDto {
  id!: string
  name!: string
  imageId!: number
  size!: { width: number; height: number }
  state!: PlayerState
  lines!: LineDto[]
  connected!: boolean
}

export class PlayerRemovedDto {
  id!: string
}

export class CountdownStartedDto {
  ms!: number
  motif!: string
}

export class GameStartedDto {
  ms!: number
}

export class ResetGameDto {
  playerStates!: { id: string; state: PlayerState }[]
}

export class CalculationCompletedDto {
  scores!: Record<string, number>
  resetTime!: number
}

export class GameStatsDto {
  gameId!: string
  rounds!: number
  players!: {
    id: string
    wins: number
    averageScore: number
    bestMotif: { motif: string; score: number }
    worstMotif: { motif: string; score: number }
    scores: {
      motif: string
      score: number
    }[]
  }[]
}
