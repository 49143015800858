const http = {
  get: function<T>(url: string): Promise<T> {
    return fetch(url).then((r) => r.json())
  },
  post: function<T, K = {}>(url: string, body?: K): Promise<T> {
    return fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: body ? JSON.stringify(body) : null
    }).then((r) => r.json())
  }
}

export default http
